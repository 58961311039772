import { setLocale } from 'yup';

const COPY_REQUIRED = 'This field is required';

const mixedValidationSchema = {
	required: COPY_REQUIRED,
	notType: COPY_REQUIRED,
	oneOf: COPY_REQUIRED,
};

setLocale({
	mixed: mixedValidationSchema,
	string: {
		email: 'Please, enter a valid email address',
		max: ({ max }) => ({
			key: `Your input must be smaller than or equal to ${max} characters.`,
			values: { max },
		}),
		min: ({ min }) => ({
			key: 'The password is too short. Please insert at least 8 characters',
			values: { min },
		}),
		matches: 'Your input is not in a valid format.',
	},
	number: {
		min: ({ min }) => ({
			key: `Number must be greater than or equal to ${min}`,
			values: { min },
		}),
		max: ({ max }) => ({
			key: `Number must be less than than or equal to ${max}`,
			values: { max },
		}),
	},
	boolean: {
		isValue: COPY_REQUIRED,
	},
	array: {
		min: ({ min }) => ({
			key: `You must add at least ${min} element${min > 1 ? 's' : ''}`,
			values: { min },
		}),
	},
});
