import { ReactNode, useEffect, useState } from 'react';

import '@fllite-fe/shared/src/stylesheets/font-face.css';
import 'react-day-picker/dist/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '@fllite-fe/shared/yupConfig';
import 'mapbox-gl/dist/mapbox-gl.css';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import { FlashMessages, getContextForApp, ProvideAnalytics, useSentry } from '@fllite-fe/shared';
import { GlobalStyles } from '@fllite-fe/shared/src/components/GlobalStyle';
import { ClientSectionAuthWrapper } from '@fllite-fe/shared/src/hooks/useRedirects';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

interface Props extends AppProps {}

const App = ({ Component, pageProps }: Props) => {
	const [showChild, setShowChild] = useState(false);

	useSentry();

	useEffect(() => {
		setShowChild(true);
	}, []);

	if (!showChild || typeof window === 'undefined') {
		return <div />;
	}

	return (
		<>
			<Head>
				<title>Fllite</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				/>
			</Head>
			<Script src="https://www.google.com/recaptcha/enterprise.js?render=explicit" />
			<AppProviders trackPageChanges>
				<GlobalStyles />
				<CssBaseline />
				<Component {...pageProps} />
			</AppProviders>
		</>
	);
};

interface AppProvidersProps {
	children: ReactNode;
	trackPageChanges?: boolean;
}

export const AppProviders = ({ children, trackPageChanges }: AppProvidersProps) => {
	const { googleClientId } = getContextForApp();
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnMount: false,
						refetchOnWindowFocus: false,
					},
				},
			}),
	);

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<MuiThemeProvider theme={muiTheme}>
				<ProvideAnalytics trackPageChanges={trackPageChanges}>
					<GoogleOAuthProvider clientId={googleClientId}>
						<FlashMessages>
							<ClientSectionAuthWrapper>{children}</ClientSectionAuthWrapper>
						</FlashMessages>
					</GoogleOAuthProvider>
				</ProvideAnalytics>
			</MuiThemeProvider>
		</QueryClientProvider>
	);
};

export default App;
