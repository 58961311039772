import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

export const GlobalStyles = () => (
	<MuiGlobalStyles
		styles={{
			html: {
				fontSize: '62.5%',
				fontFamily: 'poppins',
			},

			'span, strong, p, button': {
				fontFamily: 'poppins',
			},
			body: {
				fontFamily: 'poppins',
				fontSize: '16px',
				fontWeight: 400,
				lineHeight: 1.56,
				overflowX: 'hidden',
				overscrollBehaviorY: 'none',
			},
			input: {
				fontFamily: 'poppins',
			},
			a: {
				color: '#4183c4',
			},

			'a, a:hover': {
				textDecoration: 'none',
			},
			'h1, h2, h3, h4, h5, p': {
				fontFamily: 'poppins',
			},
			'.gm-ui-hover-effect': {
				display: ' none !important',
			},
		}}
	/>
);
